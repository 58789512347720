import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import DownloadButton from "../../components/downloadButton";

const SEG = () => {

    const folder = '/downloads/supernovaunit'

    const files = [
        {
            title:'Earth Globe Demo Write-up',
            image:'/supernova.png',
            links:[
                {name:'Earth Globe PDF',link:`${folder}/Eglobeactilitho.pdf`},
                {name:'Accessible PDF',link:`${folder}/Eglobeactilitho508.pdf`},
            ]
        },
        {
            title:'SuperNova Poster',
            image:'/sn_poster_front.png',
            links:[
                {name:'Front Side',link:`${folder}/supernoposter2.jpg`},
                {name:'Back Side',link:`${folder}/snpostbackepoprt.pdf`},
            ]
        },
        {
            title:'SuperNova Educator Guide',
            image:'/borealis.png',
            links:[
                {name:'Educator Guide PDF',link:`${folder}/snguide13web.pdf`},
                {name:'Accessible PDF',link:`${folder}/snguide5_508.pdf`},
            ]
        },
        {
            title:'Activity 1',
            image:'/act1.png',
            links:[
                {name:'Activity 1 File',link:`${folder}/CardOnlyBiz.pdf`},
            ]
        },
        {
            title:'Activity 2',
            image:'/act2.png',
            links:[
                {name:'Crab Nebula 1956',link:`${folder}/crab_1956.jpg`},
                {name:'Crab Nebula 1999',link:`${folder}/crab_1999.jpg`},
                {name:'Crab Nebula Expansion',link:`${folder}/crab_expansion_animation.gif`},
                {name:'Crawl of the Crab Activity (Teacher)',link:`${folder}/crawlofthecrab_answerkey.xls`},
                {name:'Crawl of the Crab Activity (Student)',link:`${folder}/crawlofthecrab_studentversion.xls`},
                {name:'Crawl of the Crab Activity (Online)',link:`${folder}/SN_Activity2_onscreen.pdf`},
                
            ]
        },
    ]

    return (
        <Layout>
            <div>
                <h1>Supernova Educator Guide</h1>
                <Star />
                <div className="media-items-container">
                    {files.map( (file,index) =>
                        <div className="item-container" key={index}>
                            <img src={file.image} className='image-styles' alt='media'/>
                            <h4 style={{margin:'20px'}}>{file.title}</h4>
                            
                            <div>
                                {file.links.map( (link,indx) =>
                                    <DownloadButton key={indx} text={link.name} link={link.link}/>
                                )}
                            </div>
                        </div>    
                    )}
                </div>

            </div>
        </Layout>
    )
}

export default SEG